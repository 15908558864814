import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import HomeView from "@/views/HomeView.vue";
import ComingSoonView from "@/views/ComingSoonView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: ComingSoonView,
    meta: {
      title: "Coming Soon | Wiskers",
      metaTags: [
        {
          description: "Our website will soon be available!",
        },
      ],
    },
  },
  // {
  //   path: "/",
  //   alias: "/home",
  //   name: "home",
  //   component: HomeView,
  //   meta: {
  //     title: "Home | Wiskers",
  //     metaTags: [
  //       {
  //         description: "",
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () => import("../views/AboutView.vue"),
  //   meta: {
  //     title: "About Us | Wiskers",
  //   },
  // },
  // {
  //   path: "/contacts",
  //   name: "contacts",
  //   component: () => import("../views/ContactsView.vue"),
  //   meta: {
  //     title: "Contact Us | Wiskers",
  //   },
  // },
  // {
  //   path: "/services/dog-walking/",
  //   name: "dog-walking",
  //   component: () => import("../views/DogWalkingView.vue"),
  //   meta: {
  //     title: "Dog Walking | Wiskers",
  //   },
  // },
  // {
  //   path: "/services/pet-sitting/",
  //   name: "pet-sitting",
  //   component: () => import("../views/PetSittingView.vue"),
  //   meta: {
  //     title: "Pet Sitting | Wiskers",
  //   },
  // },
  // {
  //   path: "/services/pet-model-shoots/",
  //   name: "pet-model-shoots",
  //   component: () => import("../views/PetModelShootsView.vue"),
  //   meta: {
  //     title: "Pet Model Shoots | Wiskers",
  //     metaTags: [],
  //   },
  // },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/coming-soon",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      if (to.hash) {
        resolve({ el: to.hash, top: 0, behavior: "smooth" });
      } else if (savedPosition) {
        setTimeout(() => {
          resolve({ top: savedPosition.top });
        }, 500);
      } else {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 500);
      }
    });
  },
});

router.beforeEach((to, from, next) => {
  if (typeof to.meta.title == "string") {
    document.title = to.meta.title;
  } else {
    document.title = "Wiskers";
  }

  removeMetaTags(from);
  addMetaTags(to);

  next();
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function removeMetaTags(from: any) {
  if (typeof from.meta.metaTags != "undefined") {
    const fromMetaTags: object[] = from.meta.metaTags as object[];

    fromMetaTags.forEach((item: object) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [key, value] = Object.entries(item)[0];
      const tagToRemove = document.head.querySelector(`[name = "${key}"]`);
      tagToRemove?.remove();
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addMetaTags(to: any) {
  if (typeof to.meta.metaTags != "undefined") {
    const toMetaTags: object[] = to.meta.metaTags as object[];

    toMetaTags.forEach((item: object) => {
      for (const [key, value] of Object.entries(item)) {
        const tag = document.createElement("meta");
        tag.setAttribute("name", key);
        tag.setAttribute("content", value);
        document.head.appendChild(tag);
      }
    });
  }
}

export default router;
