<template>
  <section>
    <div class="title">
      <h1>{{ $t("coming-soon.title") }}</h1>
      <h2>{{ countdown[0] }}d {{ countdown[1] }}h {{ countdown[2] }}m</h2>
    </div>
    <div>
      <div class="wrapper">
        <div class="contacts">
          <div class="socials">
            <a target="_blank" href="https://wa.me/351938451878">
              <img src="@/assets/contacts/whatsapp.svg" alt="Whatsapp" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/wiskers.pet.services/"
            >
              <img src="@/assets/contacts/instagram.svg" alt="Instagram" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/wiskers.pet.services/"
            >
              <img src="@/assets/contacts/facebook.svg" alt="Facebook" />
            </a>
          </div>
          <div class="phones xl">
            <a target="_blank" href="tel:+351936363965"
              ><img src="@/assets/contacts/phone.svg" alt="Phone" />+351
              936363965</a
            >
            <a target="_blank" href="tel:+351938451878"
              ><img src="@/assets/contacts/phone.svg" alt="Phone" />+351
              938451878</a
            >
            <a target="_blank" href="mailto:geral@wiskers.pet"
              ><img src="@/assets/contacts/mail.svg" alt="Mail" />
              <span class="break-word">geral@wiskers.pet</span>
            </a>
          </div>
        </div>
        <img
          class="hero__img"
          srcset="
            @/assets/coming-soon_1600x961.webp 1600w,
            @/assets/coming-soon_1200x721.webp 1200w,
            @/assets/coming-soon_800x480.webp   800w,
            @/assets/coming-soon_500x300.webp   500w,
            @/assets/coming-soon_380x228.webp   380w
          "
          sizes="
            (min-width: 1120px) 800px,
            (min-width: 920px) calc(78.89vw - 68px),
            (min-width: 880px) 800px,
            calc(95vw - 17px)
          "
          src="@/assets/coming-soon_1200x721.webp"
          alt="Coming Soon"
        />
      </div>
    </div>
    <div class="phones sm">
      <a target="_blank" href="tel:+351936363965"
        ><img src="@/assets/contacts/phone.svg" alt="Phone" />+351 936363965</a
      >
      <a target="_blank" href="tel:+351938451878"
        ><img src="@/assets/contacts/phone.svg" alt="Phone" />+351 938451878</a
      >
      <a target="_blank" href="mailto:geral@wiskers.pet"
        ><img src="@/assets/contacts/mail.svg" alt="Mail" />
        <span class="break-word">geral@wiskers.pet</span>
      </a>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from "vue";

let eventDate = new Date("Apr 17, 2024 00:00:00");
let countdown = ref(["12", "34", "56"]);
let timeLeft = 2;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let countdownExpired = false;

function countdownTimer() {
  if (timeLeft > 0) {
    setTimeout(() => {
      updateCountdown();
      countdownTimer();
    }, 5000);
  } else {
    countdownExpired = true;
  }
}

function updateCountdown() {
  let localEventDate = eventDate.getTime();
  let currentDate = new Date().getTime();

  let localTimeLeft = localEventDate - currentDate;
  timeLeft = localTimeLeft;

  let days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let daysString: any = days < 10 ? "0" + String(days) : String(days);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let hoursString: any = hours < 10 ? "0" + String(hours) : String(hours);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let minutesString: any =
    minutes < 10 ? "0" + String(minutes) : String(minutes);

  countdown.value[0] = daysString;
  countdown.value[1] = hoursString;
  countdown.value[2] = minutesString;
}

countdownTimer();
updateCountdown();
</script>

<style scoped>
section {
  padding: 1rem;
}

.title {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}
.title h1 {
  padding-top: 6rem;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 2px;
}

.title h2 {
  font-size: 6rem;
  letter-spacing: 1px;
}

.wrapper {
  display: flex;
  padding-top: 1rem;
  justify-content: space-around;
  max-width: 1300px;
  margin: 0 auto;
  gap: 1rem;
}

.hero__img {
  max-width: 800px;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.contacts {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  flex-shrink: 0;
}

.socials {
  display: flex;
  flex-grow: 0;
  gap: 1rem;
  justify-content: center;
}

.socials a {
  width: 40px;
  aspect-ratio: 1;
}

.socials a img {
  width: 100%;
  height: 100%;
}

.phones.xl {
  display: block;
  position: static;
}

.phones.sm {
  display: none;
  position: absolute;
}

.phones a {
  font-size: 1.4rem;
  text-decoration: none;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 1ch;
}

.phones a img {
  max-width: 3ch;
  height: auto;
}

@media screen and (max-width: 900px) {
  .title h1 {
    font-size: 2.5rem;
  }
  .title h2 {
    font-size: 5rem;
  }
  .wrapper {
    flex-direction: column;
    gap: 2rem;
  }
  .socials {
    gap: 3rem;
  }
  .phones.xl {
    display: none;
    position: absolute;
  }
  .phones.sm {
    display: flex;
    padding-top: 2rem;
    width: 100%;
    justify-content: space-evenly;
    position: static;
    flex-wrap: wrap;
    column-gap: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .title h1 {
    font-size: 2rem;
  }
  .title h2 {
    font-size: 4rem;
  }
  .socials a {
    width: 36px;
  }
  .phones a {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 400px) {
  .title h1 {
    font-size: 1.75rem;
  }
  .title h2 {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 350px) {
  .title h1 {
    font-size: 1.5rem;
  }
  .title h2 {
    font-size: 3rem;
  }
  .socials {
    gap: 2rem;
  }
  .socials a {
    width: 32px;
  }
  .phones a {
    font-size: 1.1rem;
  }
}
</style>
