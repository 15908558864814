import { createI18n } from "vue-i18n";
import pt from "./locales/pt.json";
import en from "./locales/en.json";

function getLocale(): string {
  const cookieLocale = document.cookie
    .split("; ")
    .find((row) => row.startsWith("locale"));

  if (cookieLocale) {
    return cookieLocale.split("=")[1];
  } else return "pt";
}

const options = {
  legacy: false,
  locale: getLocale(),
  localeFallback: "en",
  globalInjection: true,
  messages: {
    pt,
    en,
  },
};

const i18n = createI18n(options);
setI18nLanguage(i18n, options.locale, false);
export default i18n;

export function setI18nLanguage(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  i18n: any,
  locale: string,
  storeToCookies = true
) {
  i18n.global.locale.value = locale;
  document.documentElement.setAttribute("lang", locale);
  if (storeToCookies) {
    document.cookie = `locale=${locale}`;
  }
}
