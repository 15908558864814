<template>
  <div class="nav-menu__wrapper">
    <nav class="alt">
      <!-- <router-link to="/">Home</router-link>
      <button
        @click="isSubMenu1Open = !isSubMenu1Open"
        :class="{ active: isSubMenu1Open }"
        class="sub-menu"
      >
        <p>
          <img src="@/assets/down-arrow.svg" aria-hidden="true" />
          Services
        </p>
        <div class="items">
          <router-link to="/services/dog-walking">Dog Walking</router-link>
          <router-link to="/services/pet-sitting">Pet Sitting</router-link>
          <router-link to="/services/pet-model-shoots"
            >Pet Model Shoots</router-link
          >
        </div>
      </button>
      <router-link to="/about">About</router-link>
      <router-link to="/contacts">Contacts</router-link> -->
      <!-- <router-link to="/">{{ $t("home.services.1") }}</router-link> -->
      <button
        @click="isSubMenu2Open = !isSubMenu2Open"
        :class="{ active: isSubMenu2Open }"
        class="language-select sub-menu"
        aria-label="Switch locales"
      >
        <p>
          <img src="@/assets/down-arrow.svg" aria-hidden="true" />
          {{ localeStr }}
        </p>
        <div class="items">
          <button
            @click="switchLocale('en')"
            aria-label="Change locale to english"
          >
            EN
          </button>
          <button
            @click="switchLocale('pt')"
            aria-label="Change locale to portuguese"
          >
            PT
          </button>
        </div>
      </button>
    </nav>
  </div>
</template>
<script setup lang="ts">
import { ref, defineEmits } from "vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let isSubMenu1Open = ref(false);
let isSubMenu2Open = ref(false);

const emit = defineEmits(["close"]);

import i18n from "@/i18n";
import { setI18nLanguage } from "@/i18n";

let localeStr: string = i18n.global.locale;

function switchLocale(locale: string) {
  setI18nLanguage(i18n, locale);
  localeStr = i18n.global.locale;

  emit("close");
}
</script>
<style>
.nav-menu__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--background-accent);
  padding-top: 5rem;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 0 0 0 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.nav-menu__wrapper.active {
  opacity: 1;
  pointer-events: auto;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

nav.alt {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100svh;
  overflow: auto;
}

nav.alt > * {
  border: none;
  border-bottom: 1px solid var(--text);
  padding: 1rem;
  cursor: pointer;
  background-color: var(--background-accent);
}

nav.alt > *:is(:hover, :focus-visible) {
  background-color: rgba(0, 0, 0, 0.1);
}

nav.alt > *:last-child {
  border-bottom: none;
}

nav.alt > *,
nav.alt > * > p {
  text-decoration: none;
  font-size: 1.4rem;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: max-content;
  flex-wrap: wrap;
  color: var(--text);
}

.sub-menu > p img {
  height: 1.6rem;
  width: auto;
  object-fit: cover;
}

.sub-menu.active > p img {
  rotate: 180deg;
}

.sub-menu.active:is(:hover, :focus-visible) {
  background-color: transparent;
}

.sub-menu .items {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.sub-menu.active .items {
  height: min-content;
  padding-top: 1rem;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.sub-menu .items > * {
  border: none;
  font-size: 1.3rem;
  border-bottom: 1px solid var(--text);
  text-decoration: none;
  text-align: end;
  padding: 0.8rem;
  color: var(--text);
  background-color: var(--background-accent);
  cursor: pointer;
}

.sub-menu .items > *:is(:hover, :focus-visible) {
  background-color: rgba(0, 0, 0, 0.1);
}

.sub-menu .items > *:last-child {
  border-bottom: none;
}

@media screen and (max-width: 750px) {
  .nav-menu__wrapper {
    width: 100%;
    border-radius: 0 0 0 0;
  }
}
</style>
