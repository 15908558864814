<template>
  <header
    ref="header"
    class="coming-soon"
    :class="{ home: isHomePage, fade: fadeTrigger, alt: smallerScreen }"
  >
    <router-link to="/" class="header__logo">
      <img src="@/assets/logo.webp" alt="Wiskers logo" />
    </router-link>
    <template v-if="!smallerScreen">
      <nav class="default">
        <!-- <router-link to="/">Home</router-link>
        <div class="dropdown">
          Services
          <div class="menu">
            <router-link to="/services/dog-walking">Dog Walking</router-link>
            <router-link to="/services/pet-sitting">Pet Sitting</router-link>
            <router-link to="/services/pet-model-shoots"
              >Pet Model Shoots</router-link
            >
          </div>
        </div>
        <router-link to="/about">About</router-link>
        <router-link to="/contacts">Contacts</router-link> -->
        <!-- <router-link to="/">{{ $t("home.services.1") }}</router-link> -->
        <div class="neglected">|</div>
        <div class="language-select dropdown">
          <p>
            {{ localeStr }}
            <img src="@/assets/down-arrow.svg" aria-hidden="true" />
          </p>
          <div class="menu">
            <button
              @click="switchLocale('en')"
              aria-label="Change locale to english"
            >
              EN
            </button>
            <button
              @click="switchLocale('pt')"
              aria-label="Change locale to portuguse"
            >
              PT
            </button>
          </div>
        </div>
      </nav>
    </template>
    <template v-else>
      <NavAltButton
        @open="isNavAltOpen = true"
        @close="isNavAltOpen = false"
        :close="masterClose"
      ></NavAltButton>
      <NavAltMenu
        @close="closeNavMenu"
        :class="{ active: isNavAltOpen }"
      ></NavAltMenu>
    </template>
  </header>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from "vue";

import NavAltButton from "./NavAltButton.vue";
import NavAltMenu from "./NavAltMenu.vue";
let isNavAltOpen = ref(false);
let masterClose = ref(false);

function closeNavMenu() {
  masterClose.value = true;
  isNavAltOpen.value = false;
  nextTick(() => {
    masterClose.value = false;
  });
}

let smallerScreen = ref(false);

function onResize() {
  const vw = window.innerWidth || 0;

  if (vw < 900) {
    smallerScreen.value = true;
  } else {
    smallerScreen.value = false;
  }
}

import { useRoute } from "vue-router";

const route = useRoute();
const isHomePage = ref(true);
const fadeTrigger = ref<boolean>(false);

let initialRouteChange = true;

if (route.name != "home") {
  isHomePage.value = false;
}

function headerOnRouteChange() {
  setTimeout(() => {
    if (route.name == "home") {
      isHomePage.value = true;
    } else {
      isHomePage.value = false;
    }
  }, 500);

  if (!initialRouteChange) {
    fadeTrigger.value = true;
    setTimeout(() => {
      fadeTrigger.value = false;
    }, 1000);
  }
}

async function navOnRouteChange() {
  masterClose.value = true;

  await nextTick();
  masterClose.value = false;
  isNavAltOpen.value = false;
}

watch(
  () => route.fullPath,
  () => {
    headerOnRouteChange();
    setTimeout(() => {
      navOnRouteChange();
    }, 500);
  }
);

import i18n from "@/i18n";
import { setI18nLanguage } from "@/i18n";

let localeStr: string = i18n.global.locale;

function switchLocale(locale: string) {
  setI18nLanguage(i18n, locale);
  localeStr = i18n.global.locale;
}

onMounted(() => {
  window.addEventListener("resize", onResize);
  onResize();

  setTimeout(() => {
    headerOnRouteChange();
    initialRouteChange = false;
  }, 200);
});
</script>
<style>
header {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: var(--background-accent);
  gap: 3rem;
  height: 6rem;
  position: fixed;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
header.coming-soon {
  gap: 1rem;
}
header.home {
  position: relative;
  background-color: transparent;
  box-shadow: none;
}
header.fade {
  animation: fade ease-in-out 1s;
}
header.alt {
  justify-content: space-between;
  gap: 2rem;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header__logo {
  max-width: 300px;
}
.header__logo > img {
  width: 100%;
}
nav.default {
  display: flex;
}
nav.default > * {
  display: inline-block;
  padding: 0.3rem 1ch;
  text-decoration: none;
  border-radius: 1ch;
  font-weight: 500;
  cursor: pointer;
  font-family: "Ysabeau Office";
}
nav.default > *:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

nav.default .neglected {
  cursor: default;
}

nav.default .neglected:hover {
  background-color: transparent;
}

.dropdown {
  overflow: visible;
  position: relative;
}

.dropdown:hover .menu {
  opacity: 1;
  pointer-events: all;
}

.dropdown .menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  background-color: var(--background-accent);
  border-radius: 1ch;
  box-shadow: 0 0 5px var(--text);
  overflow: hidden;
  top: 100%;
  left: 0;
  width: max-content;
}

.dropdown .menu > * {
  text-decoration: none;
  border-radius: none;
  border: none;
  border-bottom: 1px solid var(--text);
  cursor: pointer;
  padding: 0.5rem 3ch;
}

.dropdown .menu > *:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdown .menu > *:last-child {
  border-bottom: none;
}

.dropdown.language-select {
  text-transform: uppercase;
}

.dropdown.language-select > p {
  color: var(--text);
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5ch;
}

.dropdown.language-select > p img {
  width: 2ch;
  height: 1.2rem;
  object-fit: cover;
}

.dropdown.language-select:hover > p img {
  rotate: 180deg;
}
</style>
