<template>
  <button
    @click="emitEvents"
    class="hamburguer__button"
    :class="{ active: isOpen, noTransition: props.close }"
    aria-label="Toggle nav"
  >
    <div class="hamburguer__line a"></div>
    <div class="hamburguer__line b"></div>
    <div class="hamburguer__line c"></div>
  </button>
</template>

<script setup lang="ts">
import { ref, watch, defineEmits, defineProps } from "vue";

const props = defineProps(["close"]);

watch(
  () => props.close,
  () => {
    isOpen.value = false;
  }
);

let isOpen = ref(false);
const emit = defineEmits(["open", "close"]);

function emitEvents() {
  if (isOpen.value == false) {
    emit("open");
    isOpen.value = true;
  } else {
    emit("close");
    isOpen.value = false;
  }
}
</script>

<style>
.hamburguer__button {
  height: 100%;
  aspect-ratio: 1/1;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
}

.hamburguer__button:hover {
  scale: 1.02;
}

.hamburguer__line {
  height: 2.5px;
  background-color: rgb(65, 65, 65);
  border-radius: 10px;
  position: absolute;
  right: 0.5rem;
  transition: all 0.3s ease-out;
}

.hamburguer__button.noTransition .hamburguer__line {
  transition: none !important;
}

.hamburguer__button.active .hamburguer__line {
  transition: all 0.3s ease-in;
}

.hamburguer__line.a {
  width: 1.8rem;
  top: 0.9rem;
}

.hamburguer__line.b {
  width: 1.4rem;
  top: 50%;
  translate: 0 -50%;
}

.hamburguer__line.c {
  width: 1.8rem;
  bottom: 0.9rem;
}

.hamburguer__button.active .hamburguer__line.a {
  width: 1.8rem;
  top: 50%;
  translate: 0 -50%;
  rotate: 45deg;
}

.hamburguer__button.active .hamburguer__line.b {
  width: 0;
}

.hamburguer__button.active .hamburguer__line.c {
  width: 1.8rem;
  bottom: 50%;
  translate: 0 50%;
  rotate: -45deg;
}
</style>
